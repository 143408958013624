<template>
  <div>
    <b-overlay :show="show" rounded="sm">
      <b-card no-body>
        <b-tabs pills card>
          <b-tab title="Registration" active>
            <b-card-text>
              <CCard id="student_register">
                <CCardHeader>
                  <CIcon name="cil-file" />&nbsp; Student Registration
                  <div class="card-header-actions">
                    <small class="text-muted">Register</small>
                  </div>
                </CCardHeader>
                <CCardBody>
                  <h3 v-if="!showForm">Welcome to Maha-Vidya</h3>
                  <h5 v-if="!showForm">
                    This Tablet is Registred by {{ form.student_name }}
                  </h5>
                  <CForm v-if="showForm">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label
                            for="inputPassword"
                            class="col-sm-5 col-md-4 col-form-label"
                            >{{ labels.student }}</label
                          >
                          <div class="col-sm-7 col-md-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="form.student_name"
                              id="classes"
                              placeholder=""
                            />
                            <span id="name_err" style="color: red"></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label
                            for="ipMobile"
                            class="col-sm-5 col-form-label"
                            style=""
                            >{{ labels.stu_contact }}</label
                          >
                          <div class="col-sm-7">
                            <input
                              type="text"
                              class="form-control"
                              v-model="form.stu_contact"
                              id="ipMobile"
                              placeholder=""
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode <= 15"
                              maxlength="10"
                            />
                            <span id="contact_err" style="color: red"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label
                            for="inputPassword"
                            class="col-sm-5 col-md-4 col-form-label"
                            >{{ labels.stu_email }}
                          </label>
                          <div class="col-sm-7 col-md-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="form.stu_email"
                              id="classes"
                              placeholder=""
                              @blur="validateEmail"
                            />
                            <span id="email_err" style="color: red"></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label
                            for="inputPassword"
                            class="col-sm-5 col-form-label"
                            >{{ labels.adhar }} *</label
                          >
                          <div class="col-sm-7">
                            <input
                              type="text"
                              class="form-control"
                              v-model="form.adhar_no"
                              id="classes"
                              placeholder="Student Adhar Card No."
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode <= 15"
                              maxlength="12"
                            />
                            <span id="adhar_err" style="color: red"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label
                            for="inputPassword"
                            class="col-sm-5 col-md-4 col-form-label"
                            >{{ labels.dob }} *</label
                          >
                          <div class="col-sm-7 col-md-8">
                            <input
                              type="date"
                              class="form-control"
                              v-model="form.dob"
                              id="classes"
                              placeholder=""
                            />
                            <span id="dob_err" style="color: red"></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label
                            for="inputPassword"
                            class="col-sm-5 col-md-4 col-form-label"
                            >{{ labels.gender }} *</label
                          >
                          <div class="col-sm-6">
                            <b-form-group>
                              <b-form-radio-group v-model="form.gender">
                                <b-form-radio
                                  v-model="form.gender"
                                  name="some-radios"
                                  value="male"
                                  >Male</b-form-radio
                                >
                                <b-form-radio
                                  v-model="form.gender"
                                  name="some-radios"
                                  value="female"
                                  >Female</b-form-radio
                                >
                              </b-form-radio-group>
                              <span id="gender_err" style="color: red"></span>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group row">
                          <label
                            for="inputPassword"
                            class="col-sm-12 col-md-2 col-form-label"
                            >{{ labels.school }}*</label
                          >
                          <div class="col-sm-12 col-md-9">
                            <!-- <select class="form-control" v-model="form.school">
                      <option value="">Select School</option>
                      <option
                        v-for="(item, index) in school_list"
                        :key="index"
                        :value="item.school_id"
                      >
                        {{ item.school_name }}
                      </option>
                    </select> -->
                            <input
                              type="text"
                              class="form-control"
                              v-model="form.school_name"
                              id="txtschool"
                              placeholder="School Name"
                            />
                            <span id="school_err" style="color: red"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label
                            for="inputPassword"
                            class="col-sm-5 col-md-4 col-form-label"
                            >{{ labels.class }} *</label
                          >
                          <div class="col-sm-7 col-md-8">
                            <select class="form-control" v-model="form.class">
                              <option value="">Select Class</option>
                              <option
                                v-for="(item, index) in class_items"
                                :key="index"
                                :value="item.class_id"
                              >
                                {{ item.class_standard }}
                              </option>
                            </select>
                            <span id="class_err" style="color: red"></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label
                            for="inputPassword"
                            class="col-sm-5 col-md-4 col-form-label"
                            >{{ labels.division }} *</label
                          >
                          <div class="col-sm-7 col-md-8">
                            <!-- <select class="form-control" v-model="form.division">
                      <option value="">Select Division</option>
                      <option
                        v-for="(item, index) in division_items"
                        :key="index"
                        :value="item.division_id"
                      >
                        {{ item.division }}
                      </option>
                    </select> -->
                            <input
                              type="text"
                              class="form-control"
                              v-model="form.division"
                              id="txtschool"
                              placeholder="Division (A/B/C/D)"
                            />
                            <span id="division_err" style="color: red"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label
                            for="inputPassword"
                            class="col-sm-5 col-md-4 col-form-label"
                            >{{ labels.parent_name }} *</label
                          >
                          <div class="col-sm-7 col-md-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="form.parent_name"
                              id="classes"
                              placeholder=""
                            />
                            <span
                              id="parent_name_err"
                              style="color: red"
                            ></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label
                            for="inputPassword"
                            class="col-sm-5 col-form-label"
                            >{{ labels.parent_contact }} *</label
                          >
                          <div class="col-sm-7">
                            <input
                              type="text"
                              class="form-control"
                              v-model="form.parent_contact"
                              id="classes"
                              placeholder=""
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode <= 15"
                              maxlength="10"
                            />
                            <span
                              id="parent_contact_err"
                              style="color: red"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label
                            for="inputPassword"
                            class="col-sm-5 col-md-4 col-form-label"
                            >{{ labels.parent_email }} *</label
                          >
                          <div class="col-sm-7 col-md-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="form.parent_email"
                              id="classes"
                              placeholder=""
                              @blur = "parentvalidateEmail"
                            />
                            <span
                              id="parent_email_err"
                              style="color: red"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group row">
                          <label
                            for="inputPassword"
                            class="col-sm-12 col-md-2 col-form-label"
                            >{{ labels.address }}</label
                          >
                          <div class="col-sm-12 col-md-10">
                            <input
                              type="text"
                              class="form-control"
                              id="classes"
                              v-model="form.address"
                              placeholder="Enter Address"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label
                            for="inputPassword"
                            class="col-sm-5 col-md-4 col-form-label"
                            >{{ labels.city }}</label
                          >
                          <div class="col-sm-7 col-md-8">
                            <!-- <input
                      type="text"
                      class="form-control"
                      v-model="form.city"
                      id="classes"
                      placeholder=""
                    /> -->
                            <select class="form-control" v-model="form.city">
                              <option value="">Select District</option>
                              <option
                                v-for="(item, index) in districts"
                                :key="index"
                                :value="item"
                              >
                                {{ item }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label
                            for="inputPassword"
                            class="col-sm-5 col-md-4 col-form-label"
                            >{{ labels.pincode }}</label
                          >
                          <div class="col-sm-7 col-md-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="form.pincode"
                              id="classes"
                              placeholder=""
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode <= 15"
                              maxlength="6"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-6">
                <div class="form-group row">
                  <label for="inputPassword" class="col-sm-4 col-form-label"
                    >{{ labels.registration_date }} *</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="date"
                      class="form-control"
                      v-model="form.registration_date"
                      id="classes"
                      placeholder=""
                    />
                    <span id="registration_date_err" style="color: red"></span>
                  </div>
                </div>
              </div> -->
                    </div>
                    <br />
                  </CForm>
                  <div class="row" v-if="showForm">
                    <div class="col-lg-10 col-md-8"></div>
                    <div class="col-lg-2 col-md-4 float-right">
                      <CButton color="primary" @click="save_data">{{
                        labels.submit
                      }}</CButton>
                      <CButton class="ml-2" color="danger" @click="onReset">{{
                        labels.reset
                      }}</CButton>
                    </div>
                  </div>
                </CCardBody>
              </CCard>
            </b-card-text>
          </b-tab>
          <b-tab title="Applications">
            <b-card-text>
               <CCard>
              <CCardHeader>
                Application
                <small> Details</small>
              </CCardHeader>
              <CCardBody>
              <div>
                  <CCard class="mb-0" v-for="(item,index) in items" :key="index">
                    <CButton 
                      block 
                      color="link"
                      class="text-left shadow-none card-header" 
                      @click="accordion = accordion === index+1 ? false : index+1" 
                      style="text-decoration:none;"
                    >
                      <h5 class="m-0">{{item.application_name}}</h5>
                    </CButton>
                    <CCollapse :show="accordion === index+1">
                      <CCardBody style="font-size:14px;">
                        <div class="row" style="margin:0px;margin-top:0px;">
                            <p v-if="item.islist == true">
                              <ul style="list-style-type:disc;font-size:14px;">
                                  <span v-for="(itm,index) in item.application_details" :key="index" v-if="index <= 1">
                                  {{itm}}<br/>
                                  </span>
                                <li style="margin-left:20px;margin-top:5px;" v-for="(itm,index) in item.application_details" :key="index" v-if="index > 1"> {{itm}}</li>
                                
                              </ul>
                            </p>
                           <p v-else-if="Array.isArray(item.application_details)">
                            <!-- <ul> -->
                              <span v-for="(itm,index) in item.application_details" :key="index">
                                {{itm}}
                              </span>
                            <!-- </ul> -->
                           </p>
                           <p v-else>{{item.application_details}}</p>
                        </div>
                      </CCardBody>
                      </CCollapse>
                    </CCard>
                  </div>
                </CCardBody>
              </CCard>
              <!-- <CCard id="registration_details">
                <CCardHeader>
                  <CIcon name="cil-calculator" />&nbsp; Registration Details
                </CCardHeader>
                <CCardBody>
                  <CCard id="registration_details" accent-color="warning">
                    <CCardBody>
                      <b-row>
                        <b-col sm="4">
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filter"
                              type="search"
                              placeholder="Type to Search"
                            ></b-form-input>
                          </b-input-group>
                        </b-col>
                        <b-col offset-sm="5" sm="3">
                          Total Records : {{ totalRows }}
                        </b-col> </b-row
                      ><br />
                      <div class="table-responsive">
                        <b-table
                          :striped="striped"
                          :bordered="bordered"
                          :borderless="borderless"
                          :outlined="outlined"
                          :small="small"
                          :hover="hover"
                          :dark="dark"
                          :fixed="fixed"
                          :foot-clone="footClone"
                          :no-border-collapse="noCollapse"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          :items="items"
                          :fields="fields"
                          :current-page="currentPage"
                          :per-page="perPage"
                          :head-variant="headVariant"
                          :table-variant="tableVariant"
                          class="table"
                          style="width: 100%; position: relative"
                        >
                        </b-table>
                      </div>
                      <b-row>
                        <b-col sm="2">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                        </b-col>
                        <b-col offset-sm="7" sm="3">
                          <b-form-group
                            horizontal
                            label="Per page"
                            label-size="sm"
                            :label-cols="6"
                          >
                            <b-form-select
                              :options="pageOptions"
                              v-model="perPage"
                              size="sm"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCard> -->
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";
import apis from "../../apis/index";
import $ from "jquery";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "studentRegister",
  data() {
    return {
      accordion: 1,
      content_data: [],
      isLoading: false,
      show: false,
      showForm: true,
      fullPage: true,
      file_attachment: "",
      role: "",
      user_role_id: "",
      student_name: "",
      teacher_name: "",
      academic_items: [],
      totalRows: "",
      filter: "",
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15, 30, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterOn: [],
      parent_email:"",
      fields: [
        {
          key: "studentname",
          label: "Student Name",
          sortable: true,
          thStyle: { width: "200px !important" },
        },
        {
          key: "contact",
          label: "Contact No.",
          sortable: true,
          thStyle: { width: "200px !important" },
        },
        {
          key: "Adhar_No.",
          label: "Adhar Card No.",
          sortable: true,
          thStyle: { width: "200px !important" },
        },
        {
          key: "DOB",
          label: "DOB",
          sortable: true,
          thStyle: { width: "200px !important" },
        },
        {
          key: "Gender",
          label: "Gender",
          sortable: true,
          thStyle: { width: "200px !important" },
        },
        {
          key: "SchoolName",
          label: "School Name",
          sortable: true,
          thStyle: { width: "200px !important" },
        },
        {
          key: "Class",
          label: "Class",
          sortable: true,
          thStyle: { width: "200px !important" },
        },
        {
          key: "Division",
          label: "Division",
          sortable: true,
          thStyle: { width: "200px !important" },
        },
        {
          key: "ParentName",
          label: "Parent Name",
          sortable: true,
          thStyle: { width: "200px !important" },
        },
        {
          key: "ParentContactNo",
          label: "Parent Contact No",
          sortable: true,
          thStyle: { width: "200px !important" },
        },
        {
          key: "ParentEmail",
          label: "Parent Email",
          sortable: true,
          thStyle: { width: "200px !important" },
        },
        {
          key: "Address",
          label: "Address",
          sortable: true,
          thStyle: { width: "200px !important" },
        },
        {
          key: "District",
          label: "District",
          sortable: true,
          thStyle: { width: "200px !important" },
        },
        {
          key: "Pincode",
          label: "Pincode",
          sortable: true,
          thStyle: { width: "200px !important" },
        },
        //{ key: 'actions', label: 'Action' }
      ],
      items: [
        {
          application_name:'PDF Reader',
          application_details:'A free PDF reader is an offline PDF viewer which provides PDF reader, Word reader, and Excel Viewer, and PowerPoint viewer without internet.'
        },
        {
          application_name:'DuoLingo',
          application_details:[
            "Learn Spoken English and 30 other languages for free",
            "Learn English with fun mini-lessons that feel like games! Use the free app every day to quickly improve your spoken English.",
            "With Duolingo, you’ll improve your English — and have fun. Short lessons help you practice speaking, reading, listening, and writing to improve your vocabulary and pronunciation of English. Start with basic phrases and sentences, and learn new words daily."
          ]
        },
        {
          application_name:'YouTube',
          application_details:[
            "This app used to watch your favorite videos and youtube channels",
            "See what the world is watching -- from the hottest music videos to what’s popular in gaming, fashion, beauty, news, learning and more. Subscribe to channels you love, create content of your own, share with friends, and watch on any device."
          ]
        },
        {
          application_name:'Google',
          application_details:[
            "The smarter way to search on the go.",
            "The Google app keeps you in the know about the things that you care about. Find quick answers, explore your interests and get a feed of updates on what matters to you. The more you use the Google app, the better it gets."
          ]
        },
        {
          application_name:'Calender',
          application_details:[
            "Calendar app help to keep work, family and personal events in sync.",
            "Easy Schedule & Agenda Planner has everything you need to organise your diary."
          ]
        },
        {
          application_name:'Pager',
          application_details:'The purpose of the Pager plugin is to receive instant Push messages from the MDM admin server.'
        },
        {
          application_name:'Sci Calc',
          application_details:'Scientific calculator for fractions, trigonometry and statistics Replace your traditional calculator with this easy to use app. You may even use it in tests with our special exam mode which turns your phone or tablet into a handheld calculator. Millions of people around the world use GeoGebra to learn math and science.'
        },
        {
          application_name:'LOs SMART Q',
          application_details:'Learning Outcomes - Students can ascertain the level of their own study.'
        },
        {
          application_name:'Calibre-go',
          application_details:[
            "Calibre-go lets you browse your calibre e-book library anytime, anywhere.",
            "Move your calibre e-book library to Google Drive™, Microsoft OneDrive or Dropbox and access the library through Calibre-go to browse, sort, search and read books on your mobile."
          ]
        },
        {
          application_name:'Messages',
          application_details:'This app used to Message anyone from mobile or desktop with the reliability of texting and the richness of chat. Stay in touch with friends and family, send group texts and share your favourite pictures, GIFs, emoji, stickers, videos and audio messages.'
        },
        {
          application_name:'Clock',
          islist:true,
          application_details:[
            "Simple and beautiful alarm clock",
            "Clock combines all of the functionality you need - ",
            "Set alarms, add timers, and run a stopwatch.",
            "Keep track of time around the world using the World Clock.",
            "Set a bedtime schedule, listen to sleep sounds, and see your calendar."
          ]
        },
        {
          application_name:'DIKSHA',
          application_details:[
            "DIKSHA is India's national digital infrastructure for school education.",
            "The DIKSHA platform offers teachers, students and parents engaging learning material relevant to the prescribed school curriculum. Teachers have access to aids like lesson plans, worksheets and activities, to create enjoyable classroom experiences. Students understand concepts, revise lessons and do practice exercises. Parents can follow classroom activities and clear doubts outside school hours."
          ]
        },
        {
          application_name:'Gallary',
          application_details:[
            "The home for your memories. Relive, share, and organize your photos.",
            "With help of this app all your photos and videos, automatically organized and easy to share."
          ]
        },
        {
          application_name:'Classroom',
          application_details:[
            "Connect with your classes and do homework on the go.",
            "Classroom is a free service for schools, non-profits and anyone with a personal Google Account. Classroom makes it easy for learners and instructors to connect – inside and outside of schools. Classroom saves time and paper and makes it easy to create classes, distribute assignments, communicate and stay organised."
          ]
        },
        {
          application_name:'Drive',
          application_details:'Google Drive, part of Google Workspace, is a safe place to back up and access all your files from any device. Easily invite others to view, edit or leave comments on any of your files or folders.'
        },
        {
          application_name:'Maps',
          application_details:[
            "This app used to real-time traffic updates, navigation & local suggestions for food and events.",
            "Navigate your world faster and easier with Google Maps.  Over 220 countries and territories mapped and hundreds of millions of businesses and places on the map.  Get real-time GPS navigation, traffic, and transit info, and explore local neighborhoods by knowing where to eat, drink and go - no matter what part of the world you’re in."
          ]
        },
        {
          application_name:'Calculator',
          islist:true,
          application_details:[
            "Calculator provides simple and advanced mathematical functions in a beautifully designed app.",
            "Calculator combines all of the functionality you need - ",
            "Perform basic calculations such as addition, subtraction, multiplication, and division.",
            "Do scientific operations such as trigonometric, logarithmic, and exponential functions.",

          ]
        },
        {
          application_name:'Chrome',
          application_details:[
            "Browse fast with up to 60% data savings, downloads & secured with Safe Browsing.",
            "Google Chrome is a fast, easy to use, and secure browser. Designed for Android, Chrome brings you personalized news articles, quick links to your favorite sites, downloads, and Google Search and Google Translate built-in."
          ]
        },
        {
          application_name:'Kompkin',
          application_details:'Kompkin has designed and developed eLearning content from Std 1 to Std 12 covering all subjects in English, Marathi, Semi English, mapped to the Maharashtra State Board syllabus. It is Easy to Understand; Real-life Examples; Well Researched Content with Question Bank.'
        },
        {
          application_name:'Myfile',
          application_details:[
            "My Files your managing tool for files in the local, MicroSD, LAN.",
            "My Files manages all the files on your smartphone, just like a file explorer on your computer. You can also manage files stored on SD cards, USB drives and files in the cloud storage connected with your smartphone at the same time."
          ]
        },
        {
          application_name:'aPuppet',
          application_details:[
            "Remote access to your device and screen mirroring in a PC web browser.",
            "aPuppet is a free and open source tool for enabling remote access to your Android device.",
            "aPuppet makes it easy to get technical support for both private and business users."
          ]
        },
        {
          application_name:'Google Meet',
          application_details:[
            "Secure video conferencing.",
            "Securely connect, collaborate and celebrate from anywhere. With Google Meet, everyone can safely create and join high-quality video meetings for groups of up to 250 people."
          ]
        },
        {
          application_name:'Team Viewer',
          application_details:[
            "Unattended remote control of your Android devices.",
            "Mirror your Android devices to any computer from anywhere, at any time! Permanently access your unattended Android devices."
          ]
        },
        {
          application_name:'Maha-Vidya',
          application_details:[
            "Online school for everyone",
            "MDM Registration",
            "Student Registration"
          ]
        },
        {
          application_name:'eBalbharati',
          application_details:'Study material for standard 1 st to 10 th as per Maharashtra board'
        }


      ],

      tableVariants: [
        "primary",
        "secondary",
        "info",
        "danger",
        "warning",
        "success",
        "light",
        "dark",
      ],
      striped: false,
      bordered: true,
      borderless: false,
      outlined: false,
      small: true,
      hover: false,
      dark: false,
      fixed: true,
      footClone: false,
      headVariant: null,
      tableVariant: "",
      noCollapse: false,
      class_items: [
        { class_id: 9, class_standard: 9 },
        { class_id: 10, class_standard: 10 },
        { class_id: 11, class_standard: 11 },
        { class_id: 12, class_standard: 12 },
      ],
      student_email:"",
      districts: [
        "Ahmednagar",
        "Akola",
        "Amravati",
        "Aurangabad",
        "Beed",
        "Bhandara",
        "Buldhana",
        "Chandrapur",
        "Dhule",
        "Gadchiroli",
        "Gondia",
        "Hingoli",
        "Jalgaon",
        "Jalna",
        "Kolhapur",
        "Latur",
        "Mumbai City",
        "Mumbai Suburban",
        "Nagpur",
        "Nanded",
        "Nandurbar",
        "Nashik",
        "Osmanabad",
        "Palghar",
        "Parbhani",
        "Pune",
        "Raigad",
        "Ratnagiri",
        "Sangli",
        "Satara",
        "Sindhudurg",
        "Solapur",
        "Thane",
        "Wardha",
        "Washim",
        "Yavatmal",
      ],
      subject_items: [],
      division_items: [],
      school_list: [],
      form: {
        student_id: "00000000-0000-0000-0000-000000000000",
        school_name: "",
        title_upload: "",
        userName: "",
        password: "",
        student_name: "",
        parent_contact: "",
        parent_email: "",
        address: "",
        city: "",
        pincode: "",
        dob: "",
        gender: "",
        stu_contact: "",
        stu_email: "",
        adhar_no: "",
        checked: [],
        division: "",
        class: "",
        registration_date: new Date(),
      },
      foods: ["Remember Me"],
      labelsEnglish: {
        school: "School Name",
        title_upload: "Title",
        title: "Upload Documents",
        subject: "Subject ",
        class: "Class",
        division: "Division",
        student: "Student Name",
        description: "Description",
        parent_name: "Parent Name",
        parent_email: "Parent Email",
        parent_contact: "Parent Contact",
        address: "Address",
        city: "City",
        pincode: "Pincode",
        dob: "DOB",
        gender: "Gender",
        adhar: "Adhar Card No.",
        stu_contact: "Contact No.",
        stu_email: "Email-Id",
        submit: "Submit",
        reset: "Reset",
        registration_date: "Registration Date",
      },
      labels: {
        school: "School Name (शाळेचे नाव)",
        school_address: "School Address	(शाळेचा पत्ता)",
        title: "Upload Study Material",
        title_upload: "Title",
        subject: "Subject",
        Year: "Academic Year",
        class: "Class (इयत्ता)",
        division: "Division (तुकडी)",
        student: "Student Name (विद्यार्थ्याचे नाव)*",
        parent_name: "Parent Name (पालकांचे नाव)",
        parent_email: "Parent Email (पालकांचा ई-मेल)",
        parent_contact: "Parent Contact (पालकांचा भ्रमरध्वनी क्रमांक)",
        address: "Address (संपूर्ण पत्ता)",
        city: "District (जिल्हा)",
        pincode: "Pincode (पिन कोड)",
        dob: "DOB (जन्म दिनांक)",
        gender: "Gender (लिंग)",
        adhar: "Adhar Card No. (आधार कार्ड क्रमांक)",
        stu_contact: "Contact No. (विद्यार्थ्याचा भ्रमरध्वनी क्रमांक)",
        stu_email: "Email-Id (विद्यार्थ्याचा ई-मेल)",
        description: "Description",
        submit: "Submit",
        reset: "Reset",
        registration_date: "Registration Date",
      },
      labelsMarathi: {
        school: "School Name",
        title_upload: "शीर्षक",
        title: "महा-विद्या मध्ये आपले स्वागत आहे",
        subject: "विषय",
        Year: "Academic Year",
        class: "वर्ग",
        file: "फाईल",
        student: "Student Name",
        parent_name: "Parent Name",
        parent_email: "Parent Email",
        parent_contact: "Parent Contact",
        address: "Address",
        city: "City",
        pincode: "Pincode",
        dob: "DOB",
        division: "Division",
        gender: "Gender",
        adhar: "Adhar Card No.",
        stu_contact: "Contact No.",
        stu_email: "Email-Id",
        description: "वर्णन",
        submit: "स्वीकारा",
        reset: "पुनर्स्थापित",
        registration_date: "Registration Date",
      },
    };
  },
  components: {},
  methods: {
    validateEmail() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.stu_email)) {
        this.student_email = this.form.stu_email;
      } else {
        this.student_email = "";
        $("#email_err").css("color","red").text("Please Enter Valid Email Address *").show();
        setTimeout(function(){
          $("#email_err").hide();
        },1500);
      }
    },
    parentvalidateEmail(){
       if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.parent_email)) {
        this.parent_email = this.form.parent_email;
      } else {
        this.parent_email = "";
        $("#parent_email_err").css("color","red").text("Please Enter Valid Email Address *").show();
        setTimeout(function(){
          $("#parent_email_err").hide();
        },1500);
      }
    },
    get_academic_year() {
      var promise = apis
        .list_AcademicYears()
        .then((response) => {
          this.academic_items = response.data;
        })
        .catch((error) => {});
    },
    get_class() {
      var promise = apis
        .list_ClassMasters()
        .then((response) => {
          this.class_items = response.data;
        })
        .catch((error) => {});
    },
    get_division() {
      var promise = apis
        .list_DivisionMasters()
        .then((response) => {
          this.division_items = response.data;
        })
        .catch((error) => {});
    },
    get_teacher() {
      var promise = apis
        .list_Teachers()
        .then((response) => {
          this.teacher_items = response.data;
        })
        .catch((error) => {});
    },
    get_subject() {
      var promise = apis
        .list_Subjects()
        .then((response) => {
          this.subject_items = response.data;
        })
        .catch((error) => {});
    },
    get_division() {
      var promise = apis
        .list_DivisionMasters()
        .then((response) => {
          this.division_items = response.data;
        })
        .catch((error) => {});
    },
    click_me() {
      this.isLoading = true;
      var data = {
        student_id: this.form.student,
      };
      var promise = apis
        .studentbyid(data)
        .then((response) => {
          this.isLoading = false;
          this.student_name = response.data[0].student_name;
        })
        .catch((error) => {});
    },
    click_me2() {
      this.isLoading = true;
      var data = {
        teacher_id: this.form.teacher,
      };
      var promise = apis
        .teacherbyid(data)
        .then((response) => {
          this.isLoading = false;
          this.teacher_name = response.data[0].teacher_name;
        })
        .catch((error) => {});
    },
    handleFileUpload() {
      this.file_attachment = this.$refs.file_attachment.files[0];
    },
    submit_data() {
      alert("here");
    },
    save_data() {
      if (this.form.student_name == "" || this.form.student_name == undefined) {
        $("#name_err")
          .css("color", "red")
          .text("Student Name Required *")
          .show();
        setTimeout(function () {
          $("#name_err").hide();
        }, 2000);
        return;
      }
      if (this.form.adhar_no == "" || this.form.adhar_no == undefined) {
        $("#adhar_err")
          .css("color", "red")
          .text("Adhar No. Required * ")
          .show();
        setTimeout(function () {
          $("#adhar_err").hide();
        }, 2000);
        return;
      }
      if (this.form.dob == "" || this.form.dob == undefined) {
        $("#dob_err").css("color", "red").text("DOB Field Required * ").show();
        setTimeout(function () {
          $("#dob_err").hide();
        }, 2000);
        return;
      }
      if (this.form.gender == "" || this.form.gender == undefined) {
        $("gender_err").css("color", "red").text(" Gender Required * ").show();
        setTimeout(function () {
          $("#gender_err").hide();
        }, 2000);
        return;
      }
      if (this.form.class == "" || this.form.class == undefined) {
        $("class_err")
          .css("color", "red")
          .text("Please Select Class * ")
          .show();
        setTimeout(function () {
          $("#class_err").hide();
        }, 2000);
        return;
      }
      if (this.form.division == "" || this.form.division == undefined) {
        $("division_err")
          .css("color", "red")
          .text("Please Select Division * ")
          .show();
        setTimeout(function () {
          $("#division_err").hide();
        }, 2000);
        return;
      }
      if (
        this.form.registration_date == "" ||
        this.form.registration_date == undefined
      ) {
        $("date_err")
          .css("color", "red")
          .text("Registration Date Required *")
          .show();
        setTimeout(function () {
          $("#date_err").hide();
        }, 2000);
        return;
      }
      if (this.form.parent_name == "" || this.form.parent_name == undefined) {
        $("parent_name_err")
          .css("color", "red")
          .text("Parent Name Required *")
          .show();
        setTimeout(function () {
          $("#parent_name_err").hide();
        }, 2000);
        return;
      }
      if (
        this.form.parent_contact == "" ||
        this.form.parent_contact == undefined
      ) {
        $("parent_contact_err")
          .css("color", "red")
          .text("Contact No. Required *")
          .show();
        setTimeout(function () {
          $("#parent_contact_err").hide();
        }, 2000);
        return;
      }
      // if (this.form.parent_email == "" || this.form.parent_email == undefined) {
      //   $("parent_email_err")
      //     .css("color", "red")
      //     .text("Email-id Required *")
      //     .show();
      //   setTimeout(function () {
      //     $("#parent_email_err").hide();
      //   }, 2000);
      //   return;
      // }
      if (this.form.school_name == "" || this.form.school_name == undefined) {
        $("school_err")
          .css("color", "red")
          .text("School Name Required *")
          .show();
        setTimeout(function () {
          $("#school_err").hide();
        }, 2000);
        return;
      }
      this.show = true;
      //return;
      var data = {
        student_id: "00000000-0000-0000-0000-000000000000",
        student_name: this.form.student_name,
        student_contact: this.form.stu_contact,
        student_email: this.student_email,
        student_adhar: this.form.adhar_no,
        student_gender: this.form.gender,
        student_address: this.form.address,
        student_district: this.form.city,
        student_pincode: parseInt(this.form.pincode),
        student_class: this.form.class,
        student_division: this.form.division,
        student_dob: this.form.dob,
        school_name: this.form.school_name,
        parent_name: this.form.parent_name,
        parent_contact_number: this.form.parent_contact,
        parent_email_id: this.parent_email,
        created_date: new Date(),
      };
     // console.log(data);
     //  return;
      var promise = apis
        .register_Student(data)
        .then((response) => {
          this.show = false;
          console.log(response);
          localStorage.setItem("student", JSON.stringify(this.form));
          this.showForm = false;
          Swal.fire({
            width: 300,
            title: "Registered Successfully !!",
            Text: "Registered Successfully !!",
          });
          this.onReset();
        })
        .catch((error) => {
          this.show = false;
          // console.log("here");
          // console.log(error);
          Swal.fire({
            width: 300,
            title: "Something Went Wrong !!",
            Text: "Something Went Wrong !!",
          });
          //Swal.fire('Something Went Wrong !!');
        });
    },
    get_school() {
      var promise = apis
        .list_Schools()
        .then((response) => {
          this.school_list = response.data;
        })
        .catch((error) => {});
    },
    onReset() {
      this.form.student_name = "";
      this.form.stu_contact = "";
      this.form.stu_email = "";
      this.form.adhar_no = "";
      this.form.dob = "";
      this.form.gender = "";
      this.form.school_name = "";
      this.form.class = "";
      this.form.division = "";
      this.form.parent_name = "";
      this.form.parent_contact = "";
      this.form.parent_email = "";
      this.form.address = "";
      this.form.city = "";
      this.form.pincode = "";
      this.form.registration_date = "";
      // this.form.checked = [];
      // this.show = false;
      // this.$nextTick(() => {
      //     this.show = true;
      // });
    },
  },
  created() {
    // this.get_academic_year();
    //this.get_class();
    //this.get_division();
    // this.get_class();
    // this.get_subject();
    //this.get_school();
    // let user_id = this.$session.get("user_id");
    // if (user_id == undefined || user_id == null) {
    //   return "/";
    // }
    let student = JSON.parse(localStorage.getItem("student"));
    if (student) {
      this.showForm = false;
      this.form = student;
    }
    this.totalRows = this.items.length;
  },
};
</script>
<style scoped>
.table .bTableThStyle {
  width: 450px !important;
}
</style>
